import { Box, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import { useState } from "react";
import "react-infinite-calendar/styles.css";
import BinDot from "service/BinDot";
import { ModelErrors } from "sharedComponents";
import { useBinFetch } from "sharedUtils";
import CustomTable from "./common/CustomTable";
import NotesSection from "./common/NotesSection";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import "./styles.scss";

var ConfirmStockDeliveryForm = ({ form, context, errors, onSetForm }) => {
  const [open, setOpen] = useState(false);

  var {
    requestDeliveryCodes,
    requestDeliveryUnits,
    nextVisits,
    purchasePriceList,
  } = context;

  const { binTypes, binGroups } = useBinFetch(requestDeliveryCodes);

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  const rows = _.map(requestDeliveryUnits, ({ serviceCode, quantity }) => {
    const binType = binTypes[serviceCode];
    const binGroup = binGroups[binType?.binGroupId];

    return (
      <TableRow key={serviceCode}>
        <TableCell scope="row">
          <BinDot
            binGroup={binGroup}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
        </TableCell>
        <TableCell>{binType?.name}</TableCell>
        <TableCell>{serviceCode}</TableCell>
        <TableCell>{purchasePriceList[serviceCode]}</TableCell>
        <TableCell>{quantity}</TableCell>
      </TableRow>
    );
  });

  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
        <div>
          <label>Confirmed for</label>
          <ServiceCalendarDatePicker
            open={open}
            setOpen={setOpen}
            visitDays={visitDays}
            onChange={(newValue) =>
              onSetForm({ date: newValue.format("DD/MM/YYYY") })
            }
          />
        </div>
        <CustomTable
          rows={rows}
          headers={[
            "Type",
            "Name",
            "Code",
            "Price",
            "Confirm Delivery of # Units",
          ]}
        />
      </Box>

      <ModelErrors errors={errors} />
      <NotesSection form={form} onSetForm={onSetForm} />
    </div>
  );
};

export default ConfirmStockDeliveryForm;
