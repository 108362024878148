import { Box, CircularProgress, Typography } from "@mui/material";
import { getLocationId, useTicket } from "ticket/selectors";
import { useGetActiveTicketsQuery } from "api/tickets";
import TicketSummary from "./TicketSummary";
import { orderBy } from "lodash";

const ActiveTickets = () => {
  const currentTicket = useTicket();
  const locationId = getLocationId();

  // FIXME dont we have the location from the app? The scope is location
  const { data: activeTickets, isLoading } = useGetActiveTicketsQuery(
    locationId,
    {
      skip: !locationId,
    }
  );

  // Filter out the current ticket from the list
  const otherActiveTickets = activeTickets?.filter(
    (ticket) => ticket.id !== currentTicket?.id
  );

  return (
    <Box
      sx={{
        p: 2,
        pb: 0.5,
        my: 1,
        borderRadius: 2,
        background: "#fff",
      }}
    >
      <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
        Other Active Tickets
      </Typography>
      {isLoading ? (
        <>
          Loading ...
          <CircularProgress size={20} />
        </>
      ) : (
        orderBy(otherActiveTickets, ["openedOn"], ["desc"])?.map((ticket) => (
          <TicketSummary ticket={ticket} />
        ))
      )}
    </Box>
  );
};

export default ActiveTickets;
