import { Box, Chip, TableCell, TableRow, Typography } from "@mui/material";
import { Contact } from "api/tickets";
import { map, startCase } from "lodash";
import BinDot from "service/BinDot";
import { formatDate } from "sharedUtils";
import { AdhocCardTable } from "./AdhocVisitRequestCard";
import { BinGroup, BinType, StockDeliveryRequest } from "service/ticket/types";

interface StockDeliveryRequestCardProps {
  request: StockDeliveryRequest;
  binTypes: Record<string, BinType>;
  binGroups: Record<string, BinGroup>;
  inputtedBy: Contact;
}

const CardHeader: React.FC<{ request: StockDeliveryRequest }> = ({
  request,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "& .MuiTypography-root": {
          fontSize: "13px",
        },
      }}
    >
      <Typography sx={{ fontWeight: "700" }}>
        Delivery of Stock&nbsp;
      </Typography>
      {!request.date && !request.cancelledAt && !request.confirmedAt && (
        <Typography
          component="span"
          sx={{
            fontSize: "13px",
            fontWeight: "700",
            color: "#FFA600",
          }}
        >
          Awaiting Date
        </Typography>
      )}
      {request.confirmedAt && (
        <Typography sx={{ color: "#828B98" }}>
          &nbsp;confirmed for&nbsp;
          <span style={{ color: "#19E53B" }}>{formatDate(request?.date)}</span>
        </Typography>
      )}
      {request.cancelledAt && (
        <Typography sx={{ color: "#CF2D42" }}>&nbsp;Cancelled&nbsp;</Typography>
      )}
    </Box>
  );
};

const CardLabel = () => {
  return <>Deliver following</>;
};

const CardContent: React.FC<StockDeliveryRequestCardProps> = ({
  request,
  binTypes,
  binGroups,
  inputtedBy,
}) => {
  const { deliveryUnits } = request;

  const deliveryRows = map(
    deliveryUnits,
    ({ serviceCode, quantity: deliveryQuantity, visitActionId }) => {
      const binType = binTypes[serviceCode];

      const binGroup = binGroups[binType?.binGroupId];

      return (
        <TableRow key={serviceCode}>
          <TableCell
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <BinDot
              binGroup={binGroup}
              tooltipName={true}
              tooltipPlace="top"
              {...binType}
            />{" "}
            <Typography>{binType?.name}</Typography>
          </TableCell>
          <TableCell>{binType?.purchasePrice}</TableCell>

          <TableCell>{deliveryQuantity || 0}</TableCell>
          <TableCell
            sx={{
              color: "#5F6369 !important",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {visitActionId ? <span>{startCase(visitActionId)}</span> : null}

            <span>{serviceCode}</span>
          </TableCell>
        </TableRow>
      );
    }
  );

  return (
    <>
      {deliveryRows.length > 0 && (
        <AdhocCardTable
          rows={deliveryRows}
          headers={["", "Price", "Deliver Additional", "Code"]}
        />
      )}
      {request?.date ? (
        <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
          Visit confirmed for{" "}
          <span style={{ color: "#19E53B" }}>{formatDate(request?.date)}</span>
          <Typography
            component="span"
            sx={{ fontSize: "11px", color: "#828B98" }}
          >
            {" "}
            on{" "}
            <span style={{ color: "#BFBFBF" }}>
              {formatDate(request?.confirmedAt)}
            </span>{" "}
            by{" "}
            <span style={{ color: "#BFBFBF" }}>
              {inputtedBy?.nameWithoutEmail || "No Agent"}
            </span>
          </Typography>
        </Typography>
      ) : request?.cancelledAt ? (
        <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
          Visit cancelled on{" "}
          <span style={{ color: "red" }}>
            {formatDate(request?.cancelledAt)}
          </span>{" "}
          <Typography component="span" sx={{ fontSize: "11px" }}>
            by{" "}
            <span style={{ color: "#BFBFBF" }}>
              {inputtedBy?.nameWithoutEmail || "No Agent"}
            </span>
          </Typography>
        </Typography>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            sx={{
              fontSize: "13px",
              color: "#828B98",
            }}
          >
            Visit confirmed for{" "}
          </Typography>
          <Chip
            label="Awaiting Date"
            size="small"
            sx={{
              background: "#5F6369",
              color: "#BFBFBF",
              fontSize: "11px",
              fontWeight: "bold",
            }}
          />
        </Box>
      )}
    </>
  );
};

export default { CardHeader, CardLabel, CardContent };
