import React from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import _ from 'lodashExtended';
import { Btn, Scroller, Link } from 'sharedComponents'
import Service from './Service'
import GeneralIssueList from './GeneralIssueList'
import EmergencyCollectionBookingList from './EmergencyCollectionBookingList'
import { submit, requestEmergencyCollection } from '../api/operations'
import { areServiceIssuesUnresolved } from '../services/selectors'
import { areGeneralIssuesUnresolved } from '../generalIssues/selectors'

class IssueList extends React.Component {
  constructor(props) {
    super(props);
    this.promptUnsavedChange = this.promptUnsavedChange.bind(this)
  }
  render() {

    const {
      unsaved,
      onClickSave,
      onEmergencyCollection,
      submitting,
      errors,
      scrollerHeight,
      services,
      newAppSwitch,
      newAppSwitchUrl,
      betaAccess,
      legacyMode,
      newAppMode,
      unresolvedIssues,
      ticketsAwaitingResponseIds
    } = this.props

    var icon = errors ? "warning" : "check"
    var buttonColor = errors ? "danger" : "success"

    var vendorServicesList = _.size(services) > 1 ? <div className="mb20"><div className="mb5 text-muted">There are multiple services from this vendor at this location:</div>{_.map(services, (service) => {
      return <span><span className="badge badge-primary">{service.title}</span> &nbsp;</span>
    } )}</div> : null

    return (
      <div>
        <div className="mb15 mt15">
          { unsaved ? (
          <div name="button" disabled={submitting} onClick={onClickSave} className={`btn btn-block btn-sm btn-${buttonColor} btn-flat`}>
            <i className={`fa fa-${icon}`}></i> &nbsp;Save Changes
          </div>
          ) : (
            <div name="button" disabled={true} className={`btn btn-block btn-sm btn-${buttonColor} btn-flat`}>
              Saved
            </div>
            ) }
          </div>

          <div>
            {vendorServicesList}
            <GeneralIssueList/>
            <EmergencyCollectionBookingList/>
            {_.map(services, (service) => <Service key={service.id} onEmergencyCollection={onEmergencyCollection} {...service}/> )}
          </div>
          {newAppSwitch && !unsaved ? (
            <div className="mt20">
              <form action={newAppSwitchUrl} method="POST">
                <button type="submit" className="btn btn-primary">
                  <i className="fa fa-cog"></i> Switch to New App
                </button>
              </form>
            </div>
          ) : (
            betaAccess && <div className="mt20 text-muted">
              Cannot Switch to new App. This is because:
              <ul>
                {newAppMode === 'rolling_back' && (
                  <li>You have already rolledback. Developers will need to reset this location</li>
                )}
                {newAppMode === 'migrating' && (
                  <li>The new app is currently migrating. Please wait for this to complete, the page will refresh automatically.</li>
                )}
                {unresolvedIssues && (
                  <li>There are unresolved issues.</li>
                )}
                {unsaved && (
                  <li>You have unsaved changes.</li>
                )}
                {ticketsAwaitingResponseIds.length > 0 && (
                  <li>There are some tickets awaiting a response:<br/>
                    { _.map(ticketsAwaitingResponseIds, (id) => <>&nbsp;<Link href={`/admin/tickets/${id}`}>#{id}</Link></> ) }.
                    <br/>
                    You may need to refresh the page to see any changes</li>
                )}
              </ul>
            </div>
          )}
        </div>
    )

  }

  promptUnsavedChange(isUnsaved = false, leaveMessage = 'Leave with unsaved changes?') {
    // Detecting page transition (prevent leaving by setting true)
    // for React Router version > 2.4
    //this.props.router.setRouteLeaveHook(this.props.route, () => isUnsaved && confirm(leaveMessage));

    // Detecting browser close
    window.onbeforeunload = isUnsaved && (() => leaveMessage);
  }

  componentDidUpdate() {
    this.promptUnsavedChange(this.props.unsaved);
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

}

IssueList.propTypes = {
  onClickSave: PropTypes.func.isRequired,
  services: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  errors: PropTypes.bool,
  unsaved: PropTypes.bool,
  scrollerHeight: PropTypes.number
};


const mapStateToProps = (state, ownProps) => {
  const unresolvedIssues = areGeneralIssuesUnresolved(state) || areServiceIssuesUnresolved(state);
  const legacyMode = state.newAppMode === 'legacy';
  const newAppMode = state.newAppMode;
  const newAppSwitch = !unresolvedIssues && legacyMode && state.betaAccess && state.ticketsAwaitingResponseIds.length === 0;

  return {
    services: state.issues.services,
    unsaved: !state.api.saved,
    submitting: !!state.api.submitting,
    errors: !!state.api.errors,
    newAppSwitch: newAppSwitch,
    newAppSwitchUrl: state.newAppSwitchUrl,
    betaAccess: state.betaAccess,
    legacyMode: legacyMode,
    newAppMode: newAppMode,
    unresolvedIssues: unresolvedIssues,
    ticketsAwaitingResponseIds: state.ticketsAwaitingResponseIds,
    scrollerHeight: null // not certain how this works
  };
};

const mapDispatchToProps = (dispatch, {submitting}) => {
  return {
    onClickSave: () => {
      if(!submitting) { dispatch(submit()) }
    },
    onEmergencyCollection: (serviceId) => {
      dispatch(requestEmergencyCollection(serviceId))
    }
  }
}

IssueList = connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueList)


export default IssueList;
