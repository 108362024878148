import {
  Typography,
  Box,
  Chip,
  IconButton,
  Collapse,
  Link,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { camelCase, mapKeys, startCase } from "lodash";
import { formatDate } from "sharedUtils";
import { TimeChip } from "./TicketsList";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { DebugButton } from "service/GlobalDebugger";
import { useContext, useEffect, useState } from "react";
import RequestsCards from "service/report-request-box/RequestsCards";
import { useGetAllLocationRemediationsQuery } from "api/services";
import { getLocationId } from "ticket/selectors";
import AgentSelect from "./channel-footer/form/AgentSelect";
import { Ticket, useUpdateTicketMutation } from "api/tickets";
import dayjs from "dayjs";
import ReplyMarkedAsResponseExpected from "service/report-request-box/ReplyMarkedAsResponseExpected";
import AppContext from "ticket/AppContext";
import TicketSummaryClientStatus from "./TicketSummaryClienStatus";
import TicketSummaryVendorStatus from "./TicketSummaryVendorStatus";
import ReportsCards from "service/report-request-box/ReportsCards";

const icon = {
  confirmed: <CheckIcon sx={{ color: "green" }} />,
  awaiting_response: <HourglassTopIcon sx={{ color: "orange" }} />,
  cancelled: <CloseIcon sx={{ color: "red" }} />,
};

const TicketSummary = ({
  ticket,
  otherTicketsTab = false,
}: {
  ticket: Ticket;
  otherTicketsTab?: boolean;
}) => {
  const { currentTicketId } = useContext(AppContext);
  const locationId = getLocationId();
  const allReplies = [
    ...ticket?.organisationChannelAgentRepliesAwaitingResponse,
    ...ticket?.vendorChannelAgentRepliesAwaitingResponse,
  ];
  const [open, setOpen] = useState(
    otherTicketsTab ? false : true
    // : Boolean(ticket?.requests?.length > 0 || allReplies?.length > 0)
  );
  const [assignedAgentId, setAssignedAgentId] = useState<number | string>(
    ticket?.assignedAgent?.id
  );
  const [updateTicket] = useUpdateTicketMutation();
  const activeTicket = currentTicketId == ticket?.id;

  const remediationsData =
    useGetAllLocationRemediationsQuery(locationId, { skip: !locationId }).data
      ?.remediations || [];
  const remediations = remediationsData.map((remediation) =>
    mapKeys(remediation, (_, key) => camelCase(key))
  );

  const hasRequests =
    ticket?.latestVisitFailureReports?.length > 0 ||
    ticket?.requests?.length > 0 ||
    allReplies.length > 0;

  const handleAgentChange = async (event) => {
    try {
      await updateTicket({
        url: `/admin/tickets/${ticket.id}`,
        assignedAgentId: event.target.value,
      });
      setAssignedAgentId(event.target.value);
    } catch (error) {
      console.error("Error updating agent:", error);
    }
  };

  useEffect(() => {
    setAssignedAgentId(ticket?.assignedAgent?.id);
  }, [ticket?.assignedAgent?.id]);

  return (
    <Box
      sx={{
        border: activeTicket ? "none" : "1px solid #E3E6E9",
        p: 1,
        mx: -1.5,
        mb: activeTicket ? 0 : 1,
      }}
    >
      <Box sx={{ display: "flex", gap: 1, alignItems: "flex-start" }}>
        <DebugButton {...ticket} />
        <Link
          underline="none"
          href={`/admin/locations/${locationId}/app#/tickets/${ticket?.id}`}
          sx={{
            fontSize: "12px",
            color: "#454F5E",
            cursor: activeTicket ? "default" : "pointer",
            "&:hover": {
              color: activeTicket ? "#454F5E" : "#3BAFDA",
            },
          }}
        >
          #{ticket?.id}
        </Link>
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "baseline",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                color: "#454F5E",
              }}
            >
              Opened by {ticket?.openedByAssociationName}
            </Typography>
            <Typography sx={{ color: "#777", fontSize: "10px" }}>
              {formatDate(ticket?.openedOn)}
            </Typography>
          </Box>

          <TicketSummaryClientStatus ticket={ticket} />
          <TicketSummaryVendorStatus ticket={ticket} />
          {activeTicket && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography>
                {ticket.viewCount == 0
                  ? "Viewed 0 times"
                  : `Viewed ${ticket.viewCount} times. Last viewed on ${dayjs(
                      ticket.lastViewedOn
                    ).format("ddd DD MMM HH:mm")} by ${ticket.lastViewedBy}`}
              </Typography>
              <Link
                href={`/admin/tickets/${ticket.id}/ticket_views`}
                color="#3bafda"
              >
                <i className="fa fa-eye"></i>
              </Link>
            </Box>
          )}
          <Typography sx={{ color: "#777", fontSize: "10px" }}>
            {!hasRequests && ticket?.subject}
          </Typography>

          <TicketRequestsAndReports ticket={ticket} open={open} />
        </Box>

        <Box sx={{ display: "flex" }}>
          {hasRequests && (
            <IconButton
              disableRipple
              onClick={(e) => {
                e.stopPropagation();
                setOpen(!open);
              }}
              sx={{
                p: 0,
                "& .MuiSvgIcon-root": { fontSize: "35px" },
              }}
            >
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Box>
              {ticket?.new && (
                <Chip
                  label="New"
                  color="success"
                  size="small"
                  sx={{ fontSize: "12px", mr: 1 }}
                />
              )}
              <TimeChip date={ticket?.openedOn} />
            </Box>
            {activeTicket ? (
              <AgentSelect
                assignedAgentId={assignedAgentId}
                setAssignedAgentId={setAssignedAgentId}
                onChange={handleAgentChange}
              />
            ) : (
              <Typography
                sx={{
                  textAlign: "end",
                  fontSize: "12px",
                  color: ticket?.assignedAgent?.name ? "orange" : undefined,
                }}
              >
                {ticket?.assignedAgent?.name
                  ? `${ticket?.assignedAgent?.name}`
                  : "Not Assigned"}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {hasRequests && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ReportsCards reports={ticket?.latestVisitFailureReports} />
          <RequestsCards
            requests={ticket?.requests}
            remediations={remediations}
          />
          {allReplies.map((reply) => (
            <ReplyMarkedAsResponseExpected reply={reply} />
          ))}
        </Collapse>
      )}
    </Box>
  );
};

export default TicketSummary;

const TicketRequestsAndReports = ({ ticket, open }) => {
  if (
    open ||
    !(
      ticket?.requests?.length > 0 ||
      ticket?.latestVisitFailureReports?.length > 0
    )
  ) {
    return null;
  }

  return (
    <>
      {ticket?.requests?.map((r) => (
        <Typography
          key={r.id}
          sx={{
            color: "#777",
            fontSize: "10px",
            display: "flex",
            alignItems: "center",
            "& .MuiSvgIcon-root": {
              fontSize: "12px",
            },
          }}
        >
          {startCase(r.type)}
          {icon[r.status]}
        </Typography>
      ))}
      {ticket?.latestVisitFailureReports?.map((r) => (
        <Typography
          key={r.id}
          sx={{
            color: "#777",
            fontSize: "10px",
            display: "flex",
            alignItems: "center",
            "& .MuiSvgIcon-root": {
              fontSize: "12px",
            },
          }}
        >
          Failed Visit Report
          {r?.clientFeedback?.length > 0 || r?.vendorFeedback?.length > 0
            ? icon["confirmed"]
            : icon["awaiting_response"]}
        </Typography>
      ))}
    </>
  );
};
