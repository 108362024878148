import {
  Box,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AppContext from "ticket/AppContext";
import { MessageFormContext } from "../RespondToFormHolder";
import { ResponseExpectedToggleButton } from "./ResponseExpectedToggleButton";

const OrganisationMessageSection = ({
  messageRequired,
  sendMessageDefault,
  mandatoryResponseToMessage,
  requestResponseToMessageDefault,
}) => {
  const { ticket, clientClosed } = useContext(AppContext);
  const noLocationSelected = !ticket?.location?.id;
  const {
    organisationResponse,
    organisationError,
    stakeholderContacts,
    setOrganisationError,
  } = useContext(MessageFormContext);

  //const value = formParams?.organisationMessage;

  const [sendMessage, setSendMessage] = useState(sendMessageDefault);

  const handleSentMessage = () => {
    setSendMessage(!sendMessage);
    organisationResponse.setMessageRequired(!sendMessage);
  };

  useEffect(() => {
    if (organisationResponse?.message || !sendMessage) {
      setOrganisationError(false);
    }
  }, [organisationResponse?.message, sendMessage]);

  useEffect(() => {
    setSendMessage(sendMessageDefault);
    organisationResponse.setMessageRequired(sendMessageDefault);
  }, []);

  useEffect(() => {
    if (noLocationSelected) {
      setSendMessage(false);
      organisationResponse.setMessageRequired(false);
    }
    if (clientClosed) {
      setSendMessage(false);
      organisationResponse.setMessageRequired(false);
    }
  }, [ticket]);

  return (
    <Stack
      direction="column"
      spacing={0}
      sx={{
        justifyContent: "flex-start",
        alignItems: "stretch",
        padding: "6px",
        marginBottom: "6px",
        border: clientClosed ? "2px solid #A42435" : "2px solid #398D73",
        borderRadius: "4px",
      }}
    >
      {noLocationSelected && (
        <Typography color="error" fontWeight="bold" sx={{ fontSize: "12px" }}>
          This ticket does not have a location selected.
        </Typography>
      )}
      {clientClosed && (
        <Typography color="error" fontWeight="bold" sx={{ fontSize: "12px" }}>
          This ticket has been marked as Closed for the Organisation, Please
          reopen to be able to write a message.
        </Typography>
      )}
      {!noLocationSelected && !clientClosed && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            minHeight: "35px",
          }}
        >
          <Typography sx={{ fontSize: "12px", marginBottom: "6px" }}>
            Send Message to{" "}
            <Typography
              component="span"
              sx={{ color: "#398D73", fontWeight: "700", fontSize: "12px" }}
            >
              Organisation
            </Typography>
          </Typography>
          {!messageRequired && (
            <ToggleButtonGroup
              size="small"
              value={sendMessage}
              disabled={messageRequired}
              exclusive
              onChange={messageRequired ? null : handleSentMessage}
              sx={{
                "& .MuiToggleButton-root": {
                  py: 0,
                  fontSize: "12px",
                  textTransform: "none",
                  backgroundColor: "#fff",
                  "&.Mui-selected": {
                    backgroundColor: "#007bff",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#0056b3",
                    },
                  },
                },
                "& .MuiToggleButtonGroup-grouped": {
                  borderRadius: 1,
                  mx: 0,
                },
              }}
            >
              <ToggleButton value={true}>Yes</ToggleButton>
              <ToggleButton value={false}>No</ToggleButton>
            </ToggleButtonGroup>
          )}
        </Box>
      )}

      {(messageRequired || sendMessage) && (
        <Select
          size="small"
          value={organisationResponse.toContactId}
          sx={{ fontSize: "12px", marginBottom: "6px" }}
          onChange={(e) => organisationResponse.setToContactId(e.target.value)}
        >
          {stakeholderContacts?.["organisation"]?.map((contact) => (
            <MenuItem key={contact.id} value={contact.id}>
              {contact.name}
            </MenuItem>
          ))}
        </Select>
      )}
      {(messageRequired || sendMessage) && (
        <>
          <TextField
            error={organisationError}
            helperText={
              organisationError ? (
                `Please write a message ${
                  messageRequired ? "" : "or uncheck send message"
                }`
              ) : organisationResponse.resetToCannedResponse ? (
                <Typography sx={{ fontSize: "12px" }}>
                  Form has been updated since editing reponse.
                  <Link
                    underline="hover"
                    onClick={organisationResponse.resetToCannedResponse}
                  >
                    {" "}
                    Click to reset
                  </Link>
                </Typography>
              ) : null
            }
            disabled={!sendMessage}
            multiline
            size="small"
            value={organisationResponse.message}
            onChange={(e) => organisationResponse.setMessage(e.target.value)}
            InputProps={{
              sx: {
                height: "200px",
                backgroundColor: "#fff",
                overflow: "auto",
                alignItems: "flex-start",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              },
            }}
            sx={{
              flex: 1,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <ResponseExpectedToggleButton
              disabled={mandatoryResponseToMessage}
              requestResponseToMessageDefault={requestResponseToMessageDefault}
              channel="organisation"
            />
          </Box>
        </>
      )}
    </Stack>
  );
};

export default OrganisationMessageSection;
