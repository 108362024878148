import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  TooltipProps,
  Typography,
} from "@mui/material";
import Slider from "@mui/material/Slider";
import _ from "lodash";
import { useMemo, useState } from "react";
import BinDot from "service/BinDot";
import { formatDate, useBinFetch } from "sharedUtils";
import { useDebounceValue } from "usehooks-ts";
import BillingHistoryChart from "./service-box/BillingHistoryChart";

export const Specification = ({
  specifications = {},
  billingHistory = [],
  billingHistoryUnitAverages = {},
  purchasePriceList,
}) => {
  const billingHistoryLength = billingHistory.length;
  const cloneBillingHistory = _.cloneDeep(billingHistory);

  const [pickStartingFrom, setPickStartingFrom] = useDebounceValue(0, 100);
  const [hiddenServiceCodes, setHiddenServiceCodes] = useState({});
  const [orderBy, setOrderBy] = useState("lastBilledDate");
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const slicedBillingHistory = billingHistory.slice(pickStartingFrom);

  const allCodes = _.uniq([
    ..._.map(specifications, "serviceCode"),
    ..._.map(billingHistoryUnitAverages, "code"),
  ]);

  const { binTypes, binGroups } = useBinFetch(allCodes);

  const accumulator = {};

  slicedBillingHistory.forEach((entry) => {
    entry.items.forEach((item) => {
      if (!accumulator[item.serviceCode]) {
        accumulator[item.serviceCode] = { totalQuantity: 0 };
      }
      // Increment total quantity for the serviceCode
      accumulator[item.serviceCode].totalQuantity += item.quantity;
    });
  });

  // Calculate the average quantity for each serviceCode based on the total count of dates
  const billingHistoryUnitAveragesCalc = {};
  Object.keys(accumulator).forEach((code) => {
    billingHistoryUnitAveragesCalc[code] = {
      code,
      quantity: _.round(
        accumulator[code].totalQuantity / billingHistoryLength,
        2
      ),
    };
  });

  // High order function to avoid onClick={(event) => handleSort(event, 'code')}
  const createSortHandler = (property) => (_event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const rows = useMemo(() => {
    return _.map(allCodes, (code) => {
      let { quantity, visitActionId } = specifications[code] || {};
      let { quantity: averageQuantityCalc } =
        billingHistoryUnitAveragesCalc[code] || {};
      let { quantity: averageQuantity } =
        billingHistoryUnitAverages[code] || {};
      const binType = binTypes[code] || {};
      const bg = binGroups[binType.binGroupId];
      const lastBilledDate = billingHistoryUnitAverages[code]?.lastBilledDate;

      return {
        code,
        binType,
        bg,
        quantity,
        averageQuantityCalc: averageQuantityCalc || 0,
        averageQuantity: averageQuantity || 0,
        lastBilledDate,
        visitActionId,
        purchasePrice: purchasePriceList[code],
      };
    });
  }, [
    allCodes,
    specifications,
    billingHistoryUnitAveragesCalc,
    billingHistoryUnitAverages,
    binTypes,
    binGroups,
  ]);

  const sortedRows = useMemo(() => {
    return _.orderBy(
      rows,
      [
        (row) => {
          if (orderBy === "lastBilledDate") {
            // For lastBilledDate, treat empty strings as the oldest date possible
            return row.lastBilledDate
              ? new Date(row.lastBilledDate)
              : new Date(0);
          }
          // For other columns, use the value directly
          return _.get(row, orderBy);
        },
      ],
      [order]
    );
  }, [rows, orderBy, order]);

  return (
    <Box
      sx={{
        mx: 2,
      }}
    >
      <TableContainer
        sx={{
          "& .MuiTableCell-root": {
            fontSize: "11px",
            fontWeight: "inherit",
            color: "inherit",
            p: 0,
          },
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                fontWeight: "bold",
                color: "#828B98",
                border: "1px solid transparent",
              }}
            >
              <TableCell>Type</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "binType.name"}
                  direction={orderBy === "binType.name" ? order : "asc"}
                  onClick={createSortHandler("binType.name")}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "code"}
                  direction={orderBy === "code" ? order : "asc"}
                  onClick={createSortHandler("code")}
                >
                  Code
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <CustomTooltip title="Spec suggests that's these bins are being collected currently.">
                  <TableSortLabel
                    active={orderBy === "quantity"}
                    direction={orderBy === "quantity" ? order : "asc"}
                    onClick={createSortHandler("quantity")}
                  >
                    Qty
                  </TableSortLabel>
                </CustomTooltip>
              </TableCell>
              <TableCell>
                <CustomTooltip title="Price">
                  <TableSortLabel
                    active={orderBy === "purchasePrice"}
                    direction={orderBy === "purchasePrice" ? order : "asc"}
                    onClick={createSortHandler("purchasePrice")}
                  >
                    Price
                  </TableSortLabel>
                </CustomTooltip>
              </TableCell>
              <TableCell>
                <CustomTooltip title="Average Quantity, Showing the Calculated Alignment / Backend Calculation ">
                  <TableSortLabel
                    active={orderBy === "averageQuantityCalc"}
                    direction={
                      orderBy === "averageQuantityCalc" ? order : "asc"
                    }
                    onClick={createSortHandler("averageQuantityCalc")}
                  >
                    Avg Qty
                  </TableSortLabel>
                </CustomTooltip>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "lastBilledDate"}
                  direction={orderBy === "lastBilledDate" ? order : "asc"}
                  onClick={createSortHandler("lastBilledDate")}
                >
                  Last Billed Date
                </TableSortLabel>
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableCell-root": {
                border: "1px solid #F1F1F5",
              },
            }}
          >
            {sortedRows.map((row) => (
              <RowTable
                key={row.code}
                row={row}
                hiddenServiceCodes={hiddenServiceCodes}
                setHiddenServiceCodes={setHiddenServiceCodes}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography variant="h6" fontWeight="bold">
          Align Spec
        </Typography>
      </Box>
      <div className="mx-5">
        <BillingHistorySlider
          value={pickStartingFrom}
          maxValue={billingHistoryLength}
          onChange={setPickStartingFrom}
        />
      </div>
      <BillingHistoryChart
        hiddenServiceCodes={hiddenServiceCodes}
        billingHistory={slicedBillingHistory}
        cloneBillingHistory={cloneBillingHistory}
      />
    </Box>
  );
};
const RowTable = ({ row, hiddenServiceCodes, setHiddenServiceCodes }) => {
  return (
    <TableRow
      sx={{
        color: !hiddenServiceCodes[row.code] ? "inherit" : "#828B98",
      }}
    >
      <TableCell
        onClick={() =>
          setHiddenServiceCodes({
            ...hiddenServiceCodes,
            [row.code]: !hiddenServiceCodes[row.code],
          })
        }
        sx={{
          cursor: "pointer;",
        }}
      >
        <BinDot binGroup={row.bg} tooltipName={true} />
      </TableCell>
      <TableCell>
        {row.binType?.imageUrl && (
          <img
            src={row.binType?.imageUrl}
            alt={row.binType?.name}
            style={{ marginRight: "10px", width: "30px", height: "30px" }}
          />
        )}
        {row.binType?.name}
      </TableCell>
      <TableCell>{row.code}</TableCell>
      <TableCell>{row.quantity}</TableCell>
      <TableCell>{row.purchasePrice}</TableCell>
      <TableCell>
        {row.averageQuantityCalc} / {row.averageQuantity}
      </TableCell>
      <TableCell>
        {row.lastBilledDate ? formatDate(row.lastBilledDate) : ""}
      </TableCell>
      <TableCell>{row.visitActionId}</TableCell>
    </TableRow>
  );
};

const CustomTooltip = ({ title, children, placement = "top", ...props }) => {
  const customStyles = {
    tooltip: {
      fontSize: "12px",
      background: "#000",
    },
    arrow: {
      color: "#000",
    },
  };

  return (
    <Tooltip
      title={title}
      placement={placement as TooltipProps["placement"]}
      arrow
      slotProps={{
        tooltip: { style: customStyles.tooltip },
        arrow: { style: customStyles.arrow },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

const BillingHistorySlider = ({ maxValue, value, onChange }) => {
  const valueLabelFormat = (v) => {
    return `${maxValue - v}`;
  };

  const handleSliderChange = (_event: Event, newValue: number) => {
    onChange(newValue as number);
    //setLocalValue(newValue as number)
  };

  return (
    <Slider
      defaultValue={value}
      onChange={handleSliderChange}
      step={1}
      marks
      getAriaValueText={valueLabelFormat}
      valueLabelDisplay="on"
      valueLabelFormat={valueLabelFormat}
      min={0}
      max={maxValue - 1}
      track="inverted"
    />
  );
};
