import { Box, TableCell, TableRow } from "@mui/material";
import { map } from "lodash";
import BinDot from "service/BinDot";
import { ModelErrors } from "sharedComponents";
import { useBinFetch } from "sharedUtils";
import CustomTable from "./common/CustomTable";
import NotesSection from "./common/NotesSection";
import "./styles.scss";

const CancelStockDeliveryForm = ({ form, context, errors, onSetForm }) => {
  const { requestDeliveryUnits, requestDeliveryCodes, purchasePriceList } =
    context;

  const { binTypes, binGroups } = useBinFetch(requestDeliveryCodes);

  const rows = map(requestDeliveryUnits, ({ serviceCode, quantity }) => {
    const binType = binTypes[serviceCode];
    const binGroup = binGroups[binType?.binGroupId];

    return (
      <TableRow key={binType?.serviceCode}>
        <TableCell>
          <BinDot
            binGroup={binGroup}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
        </TableCell>
        <TableCell>{binType?.name}</TableCell>
        <TableCell>{binType?.serviceCode}</TableCell>
        <TableCell>{purchasePriceList[serviceCode]}</TableCell>
        <TableCell>{quantity}</TableCell>
      </TableRow>
    );
  });

  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
        <CustomTable
          rows={rows}
          headers={[
            "Type",
            "Name",
            "Code",
            "Price",
            "Cancel Delivery of # Units",
          ]}
        />

        <ModelErrors errors={errors} />
        <NotesSection form={form} onSetForm={onSetForm} />
      </Box>
    </div>
  );
};

export default CancelStockDeliveryForm;
