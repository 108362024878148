import { Box } from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useState } from "react";
import "react-infinite-calendar/styles.css";
import { ModelErrors, ShowDate } from "sharedComponents";
import CollectionInstruction from "../CollectionInstruction";
import DeliveryInstruction from "../DeliveryInstruction";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import "./styles.scss";
import ThreeColumnFlexBox from "./common/ThreeColumnFlexBox";
import NotesSection from "./common/NotesSection";

interface FormProps {
  date: string;
  sourceContactId: number;
  notes: string;
  confirmedAt: string;
}

interface ContextProps {
  serviceName: string;
  vendorLocationCode: string;
  visitPlanName: string;
  sourceIsUnknown: boolean;
  requestDeliveryUnits: any;
  requestCollectionUnits: any;
  requestCollectionCodes: string[];
  requestDeliveryCodes: string[];
  requestClearAll: boolean;
  requestedOn: string;
  nextVisits: any[];
  purchasePriceList: Record<string, string>;
}

interface ConfirmAdhocVisitFormProps {
  form: FormProps;
  context: ContextProps;
  errors: any;
  onSetForm: (form: any) => void;
}

const ConfirmAdhocVisitForm: React.FC<ConfirmAdhocVisitFormProps> = ({
  form,
  context,
  errors,
  onSetForm,
}) => {
  const [open, setOpen] = useState(false);

  const {
    requestDeliveryUnits,
    requestDeliveryCodes,
    requestCollectionUnits,
    requestCollectionCodes,
    requestClearAll,
    nextVisits,
    requestedOn,
    purchasePriceList,
  } = context;

  const nextVisit = nextVisits[0];
  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  return (
    <div>
      <Box sx={{ color: "#828B98", marginTop: 1 }}>
        Next Planned Visit: <ShowDate date={nextVisit?.date} />
        {nextVisit?.status && ` (${_.capitalize(nextVisit.status)})`}
      </Box>
      <ThreeColumnFlexBox>
        <Box>
          <label>Confirmed Visit Date</label>
          <ServiceCalendarDatePicker
            visitDays={visitDays}
            minDate={dayjs(requestedOn)}
            open={open}
            setOpen={setOpen}
            onChange={(newValue) =>
              onSetForm({ date: newValue.format("DD/MM/YYYY") })
            }
          />
        </Box>
      </ThreeColumnFlexBox>
      <Box sx={{ mt: 1 }}>
        <CollectionInstruction
          {...context}
          collectionUnits={requestCollectionUnits}
          requestCollectionCodes={requestCollectionCodes}
          clearAll={requestClearAll}
          status={"confirmation"}
          purchasePriceList={purchasePriceList}
        />

        <DeliveryInstruction
          purchasePriceList={purchasePriceList}
          deliveryUnits={requestDeliveryUnits}
          requestDeliveryCodes={requestDeliveryCodes}
          status={"confirmation"}
          {...context}
        />
      </Box>
      <ModelErrors errors={errors} />
      <NotesSection form={form} onSetForm={onSetForm} />
    </div>
  );
};

export default ConfirmAdhocVisitForm;
